.main{
    position:absolute;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: var(--neutral-light-color);
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    background-image: url('./../assets/background-image.webp');
    text-align: justify;
    line-height: 1.5rem;
    color: var(--neutral-dark-lighter-color);
    z-index: 1;
}

span{
    color: var(--primary-color);
}

.aboutSection{
    flex: 1;
    background-color: hsla(0, 0%, 10%, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.aboutContent{
    background-color: hsla(0, 0%, 90%, 0.9);
    width: 50%;
    padding: 8%;
    border: 2.5px solid var(--primary-color);
    border-radius: 10px;
    box-shadow: 0px 5px 10px hsla(150, 40%, 50%, 0.7);
    display: flex;
    align-items: center;
    justify-content: space-around;
    animation-name: slideInFromBottom;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
}

@keyframes slideInFromBottom {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.verticalSeparator{
    height: 150px;
    width: 10px;
    background-color: var(--primary-color);
    margin: 0vw 10vw 0vw 10vw;
}

.contactUsSection{
    flex: 1;
    background-color: hsla(0, 0%, 10%, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactUsContent{
    background-color: hsla(0, 0%, 90%, 0.8);
    width: 50%;
    padding: 10%;
    border: 1px solid var(--primary-color);
    border-radius: 10px;
    box-shadow: 0px 4px 8px hsla(150, 40%, 50%, 0.7);
}

@media (max-width: 900px) {

    .main{
        position: fixed;
    }

    .aboutContent{
        flex-direction: column;
    }

    .verticalSeparator{
        display: none;
    }

    .aboutContent{
        padding: 5%;
        margin: 20px;
    }

    .aboutContent h2{
        margin-bottom: 10px;
    }
}

@media (max-width: 500px) {

    .main{
        position: static;
        overflow-y: scroll;
    }

}