.header_group {
    position: fixed;
    display: flex;
    flex-direction: column;
    background-color: hsla(0, 0%, 90%, 0.95);
    animation-name: unFade;
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    z-index: 2;
}

@keyframes unFade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.header {
    height: 9.5vh;
    font-size: 15px;
    border-bottom: 0.5vh solid var(--primary-dark-color);
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 160px;
    width: 100vw;
}

.brand {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.logo {
    color: var(--second-accent-color);
    width: 25px;
    height: 25px;
    margin: 5px;
}

.brand_name {
    color: var(--neutral-dark-color);
    width: 60%;
    display: flex;
    flex-direction: row;
    margin: 5px;
    cursor: default;
}

.horizontal_page_links {
    list-style: none;
    flex-direction: row;
    justify-content: space-around;
    font-size: 14px;
}

.horizontal_page_links li {
    width: 100%;
    height: 100%;
}

.horizontal_page_links li a {
    color: var(--neutral-dark-color);
    text-decoration: none;
    padding: 10px;
}

.horizontal_page_links li a:hover {
    font-weight: bold;
    color: var(--second-accent-color);
}

.horizontal_page_links li a:active {
    color: var(--second-accent-dark-color);
}

.vertical_nav{
    align-self: flex-end;
    display: flex;
    justify-content: space-around;
    align-items: center;
    min-width: 160px;
    margin: 10px 0px 10px 0px;
    line-height: 40px;
    width: 100vw;
}

.vertical_page_links{
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 15px;
}

.vertical_page_links li{
    width: 70px;
    text-align: center;
    margin: 5px;
}

.vertical_page_links li a{
    text-decoration: none;
    color: var(--neutral-dark-color);
}

.vertical_page_links li a:hover {
    color: var(--second-accent-color);
}

.vertical_page_links li a:active {
    color: var(--second-accent-dark-color);
}

@media (max-width: 340px) {

    .horizontal_nav {
        display: none;
    }

    .horizontal_page_links {
        display: none;
    }
}

/* styles for screens between 340px and 750px (tablets) */
@media (min-width: 340px) and (max-width: 900px) {

    .horizontal_nav {
        display: none;
    }

    .horizontal_page_links {
        display: none;
    }

    .vertical_page_links{
        flex-direction: row;
    }

    .vertical_page_links li{
        width: 55px;
        text-align: center;
        background: transparent;
        margin: 5px;
    }
    
    .vertical_page_links li a{
        text-decoration: none;
    }
}

/* styles for screens larger than 750px (desktops) */
@media (min-width: 900px) {

    .vertical_nav_and_actions {
        display: none;
    }

    .horizontal_page_links {
        display: flex;
    }

    .vertical_nav {
        display: none;
    }

    .vertical_page_links {
        display: none;
    }
    
}

@media (max-width: 500px) {
    .header_group{
        position: static;
    }
}