@import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,700,700i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i&display=swap');

:root {
  /* Pallette */
  --soft-blue: hsl(210, 50%, 60%);
  --calm-green: hsl(150, 40%, 50%);
  --warm-yellow: hsl(45, 100%, 50%);
  --bright-orange: hsl(30, 100%, 50%);
  --vivid-pink: hsl(330, 70%, 50%);
  

  /* Pallette Shades */
  --soft-white: 	hsl(0, 0%, 90%);
  --soft-white-dark: 	hsl(0, 0%, 80%);
  --soft-white-light: 	hsl(0, 0%, 100%);
  --charcoal-grey: hsl(212, 11%, 23%);
  --charcoal-grey-dark: hsl(212, 11%, 13%);
  --charcoal-grey-light: hsl(212, 11%, 33%);
  --soft-blue: hsl(210, 50%, 60%);
  --soft-blue-dark: hsl(210, 50%, 50%);
  --soft-blue-light: hsl(210, 50%, 70%);
  --calm-green: hsl(150, 40%, 50%);
  --calm-green-dark: hsl(150, 40%, 40%);
  --calm-green-light: hsl(150, 40%, 60%);
  --warm-yellow: hsl(45, 100%, 50%);
  --warm-yellow-dark: hsl(45, 100%, 40%);
  --warm-yellow-light: hsl(45, 100%, 60%);
  --bright-orange: hsl(30, 100%, 50%);
  --bright-orange-dark: hsl(30, 100%, 40%);
  --bright-orange-light: hsl(30, 100%, 60%);
  --vivid-pink: hsl(330, 70%, 50%);
  --vivid-pink-dark: hsl(330, 70%, 40%);
  --vivid-pink-light: hsl(330, 70%, 60%);
  --Roboto-font-family: 'Roboto', sans-serif;
  --Lato-font-family: 'Lato', sans-serif;
  --Merriweather-font-family: 'Merriweather', serif;

  /* Default Theme */
  /*
  Use neutral colors the most 60-70%,
  primary color 20-30%,
  secondary and tertiary color 10-20%,
  accent colors 1-2%
  */
  --neutral-light-color: var(--soft-white);
  --neutral-light-darker-color: var(--soft-white-dark);
  --neutral-light-lighter-color: var(--soft-white-light);
  --neutral-dark-color: var(--charcoal-grey);
  --neutral-dark-darker-color: var(--charcoal-grey-dark);
  --neutral-dark-lighter-color: var(--charcoal-grey-light);
  --primary-color: var(--calm-green);
  --primary-dark-color: var(--calm-green-dark);
  --primary-light-color: var(--calm-green-light);
  --secondary-color: var(--soft-blue);
  --secondary-dark-color: var(--soft-blue-dark);
  --secondary-light-color: var(--soft-blue-light);
  --tertiary-color: var(--warm-yellow);
  --tertiary-dark-color: var(--warm-yellow-dark);
  --tertiary-light-color: var(--warm-yellow-light);
  --first-accent-color: var(--bright-orange);
  --first-accent-dark-color: var(--bright-orange-dark);
  --first-accent-light-color: var(--bright-orange-light);
  --second-accent-color: var(--vivid-pink);
  --second-accent-dark-color: var(--vivid-pink-dark);
  --second-accent-light-color: var(--vivid-pink-light);
  --primary-font-family: var(--Roboto-font-family);
  --secondary-font-family: var(--Lato-font-family);
  --accent-font-family: var(--Merriweather-font-family);
}

.body {
  font-family: var(--primary-font-family);
  line-height: 1rem;
  background-color: var(--neutral-light-color);
}